import { render, staticRenderFns } from "./lvscBox212.vue?vue&type=template&id=41733589&scoped=true"
import script from "./lvscBox212.vue?vue&type=script&lang=js"
export * from "./lvscBox212.vue?vue&type=script&lang=js"
import style0 from "./lvscBox212.vue?vue&type=style&index=0&id=41733589&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41733589",
  null
  
)

export default component.exports